<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Personnel Record
                <br>
                <small>(Form to be completed by employee at the time of hire)</small>
              </b-card-title>

              <validation-observer ref="simpleRules">
                <b-form>

                  <h4 class="text-center mt-2 mb-2">
                    FOR HOME CARE ORGANIZATION USE ONLY
                  </h4>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Name of Home Care Organization"
                        label-for="home-care-name"
                      >
                        <label
                          class="sr-only"
                          for="home-care-name"
                        >
                          * Name of Home Care Organization
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Name of Home Care Organization"
                          rules="required"
                        >
                          <b-form-input
                            id="home-care-name"
                            ref="homeCareName"
                            v-model="homeCareOrgSection.homeCareName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Name of Home Care Organization"
                            title="Name of Home Care Organization"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Home Care Organization Address"
                        label-for="home-care-address"
                      >
                        <label
                          class="sr-only"
                          for="home-care-address"
                        >
                          * Home Care Organization Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Home Care Organization Address"
                          rules="required"
                        >
                          <b-form-input
                            id="home-care-address"
                            ref="homeCareAddress"
                            v-model="homeCareOrgSection.homeCareAddress"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Home Care Organization Address"
                            title="Home Care Organization Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Home Care Organization Number"
                        label-for="home-care-number"
                      >
                        <label
                          class="sr-only"
                          for="home-care-number"
                        >
                          * Home Care Organization Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Home Care Organization Number"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="home-care-number"
                              ref="homeCareNumber"
                              v-model="homeCareOrgSection.homeCareNumber"
                              v-mask="['(###) ###-####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="1234 567 8900"
                              title="Home Care Organization Number"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Employment"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Employment"
                          label-for="date-employment"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-employment"
                          >
                            * Date of Employment
                          </label>
                          <flat-pickr
                            id="date-employment"
                            ref="dateEmployment"
                            v-model="homeCareOrgSection.dateEmployment"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date of Employment"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Separation"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Separation"
                          label-for="date-separation"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-separation"
                          >
                            * Date of Separation
                          </label>
                          <flat-pickr
                            id="date-separation"
                            ref="dateSeparation"
                            v-model="homeCareOrgSection.dateSeparation"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date of Separation"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <h4 class="text-center mt-2 mb-2">
                    PERSONAL
                  </h4>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Last Name"
                        label-for="last-name"
                      >
                        <label
                          class="sr-only"
                          for="last-name"
                        >
                          * Last Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          rules="required"
                        >
                          <b-form-input
                            id="last-name"
                            v-model="personalSection.lastName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Last Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* First Name"
                        label-for="first-name"
                      >
                        <label
                          class="sr-only"
                          for="first-name"
                        >
                          * First Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          rules="required"
                        >
                          <b-form-input
                            id="first-name"
                            v-model="personalSection.firstName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="First Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Middle Name"
                        label-for="middle-name"
                      >
                        <label
                          class="sr-only"
                          for="middle-name"
                        >
                          Middle Name
                        </label>
                        <b-form-input
                          id="middle-name"
                          v-model="personalSection.middleName"
                          placeholder="Middle Name"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Area Code/Telephone"
                        label-for="tel"
                      >
                        <label
                          class="sr-only"
                          for="tel"
                        >
                          * Area Code/Telephone
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Area Code/Telephone"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="tel"
                              v-model="personalSection.telephone"
                              v-mask="['(###) ###-####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="1234 567 8900"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Address"
                        label-for="address"
                      >
                        <label
                          class="sr-only"
                          for="address"
                        >
                          * Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Address"
                          rules="required"
                        >
                          <b-form-input
                            id="address"
                            v-model="personalSection.address"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Birth"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Birth"
                          label-for="date-birth"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-birth"
                          >
                            * Date of Birth
                          </label>
                          <flat-pickr
                            id="date-birth"
                            v-model="personalSection.dateBirth"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="SSN: (Voluntary for ID only)"
                        label-for="ssn"
                      >
                        <label
                          class="sr-only"
                          for="ssn"
                        >
                          SSN: (Voluntary for ID only)
                        </label>
                        <b-form-input
                          id="ssn"
                          v-model="personalSection.ssn"
                          v-mask="['###-##-####']"
                          placeholder="Social Security Number"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Last TB Test"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Last TB Test"
                          label-for="date-last-tb-test"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-last-tb-test"
                          >
                            * Date of Last TB Test
                          </label>
                          <flat-pickr
                            id="date-last-tb-test"
                            v-model="personalSection.dateLastTBTest"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Results of Last TB Test"
                        label-for="results-last-tb-test"
                      >
                        <label
                          class="sr-only"
                          for="results-last-tb-test"
                        >
                          * Results of Last TB Test
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Results of Last TB Test"
                          rules="required"
                        >
                          <b-form-input
                            id="results-last-tb-test"
                            v-model="personalSection.resultsLastTBTest"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Results of Last TB Test"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Have you ever been Employed under a Different Name?"
                        label-for="diff-name"
                      >
                        <label
                          class="sr-only"
                          for="diff-name"
                        >
                          * Have you ever been Employed under a Different Name?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Different Name"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="diffName"
                            v-model="personalSection.diffName"
                            name="diffName"
                            title="Different Name"
                          >
                            <b-form-radio
                              v-for="option in radioYN"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                              @change="dnDisabled(option.id)"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="If yes, please list all name used"
                        label-for="all-diff-name"
                      >
                        <label
                          class="sr-only"
                          for="all-diff-name"
                        >
                          If yes, please list all name used
                        </label>
                        <b-form-textarea
                          id="all-diff-name"
                          ref="allDiffName"
                          v-model="personalSection.allDiffName"
                          placeholder="All Name Used"
                          rows="1"
                          max-rows="2"
                          :disabled="diffNameDisabled == 1"
                          title="All Name Used"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Do you possess a Valid California Driver's License?"
                        label-for="driver-license"
                      >
                        <label
                          class="sr-only"
                          for="driver-license"
                        >
                          * Do you possess a Valid California Driver's License?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Driver's License"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="driverLicense"
                            v-model="personalSection.driverLicense"
                            name="driverLicense"
                            title="Driver's License"
                          >
                            <b-form-radio
                              v-for="option in radioYN"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                              @change="dlDisabled(option.id)"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="CDL Number"
                        label-for="cdl-number"
                      >
                        <label
                          class="sr-only"
                          for="cdl-number"
                        >
                          CDL Number
                        </label>
                        <b-form-input
                          id="cdl-number"
                          ref="cdlNumber"
                          v-model="personalSection.cdlNumber"
                          placeholder="CDL Number"
                          :disabled="driverLicenseDisabled == 1"
                          title="CDL Number"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <h4 class="text-center mt-2 mb-2">
                    POSITION INFORMATION
                  </h4>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Title of Position"
                        label-for="position"
                      >
                        <label
                          class="sr-only"
                          for="position"
                        >
                          * Title of Position
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Title of Position"
                          rules="required"
                        >
                          <b-form-input
                            id="position"
                            v-model="positionInfoSection.position"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Title of Position"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Time Base"
                        label-for="timebase"
                      >
                        <label
                          class="sr-only"
                          for="timebase"
                        >
                          * Time Base
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Time Base"
                          rules="required"
                        >
                          <b-form-input
                            id="timebase"
                            ref="timebase"
                            v-model="positionInfoSection.timebase"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Time Base"
                            title="Time Base"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <h4 class="text-center mt-2 mb-2">
                    EMPLOYMENT
                  </h4>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-table
                        ref="tableItems"
                        :fields="fields"
                        :items="employmentSection.tableItems"
                        responsive
                        title="Employment"
                      >

                        <!-- Column Name and Address of Employer -->
                        <template #cell(empNameAddress)="data">
                          <b-form-group class="min-width-300px mt-1rem">
                            <b-form-input
                              v-model="data.item.empNameAddress"
                              type="text"
                              placeholder="Name and Address of Employer"
                            />
                          </b-form-group>
                        </template>

                        <!-- Column Telephone -->
                        <template #cell(tel)="data">
                          <b-input-group class="min-width-300px">
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="data.item.tel"
                              v-mask="['(###) ###-####']"
                              type="tel"
                              placeholder="Area Code / Telephone"
                            />
                          </b-input-group>
                        </template>

                        <!-- Column Job Title and Type of Work -->
                        <template #cell(jobTitle)="data">
                          <b-form-group class="min-width-300px mt-1rem">
                            <b-form-input
                              v-model="data.item.jobTitle"
                              type="text"
                              placeholder="Job Title and Type of Work"
                            />
                          </b-form-group>
                        </template>

                        <!-- Column Reason for Leaving -->
                        <template #cell(reason)="data">
                          <b-form-group class="min-width-300px mt-1rem">
                            <b-form-input
                              v-model="data.item.reason"
                              type="text"
                              placeholder="Reason for Leaving"
                            />
                          </b-form-group>
                        </template>

                        <!-- Column Dates -->
                        <template #cell(dates)="data">
                          <b-row class="min-width-300px mt-1rem">

                            <b-col>
                              <b-form-group class="width-140px">
                                <b-input-group class="flatpickr">
                                  <flat-pickr
                                    v-model="data.item.from"
                                    class="form-control"
                                    :config="flatPickrConfig"
                                    placeholder="From"
                                  />
                                  <b-input-group-append>
                                    <b-button
                                      variant="outline-dark"
                                      title="Clear"
                                      class="brdr-gray px-1"
                                      data-clear
                                    >
                                      <i class="fa fa-times">
                                        <span
                                          aria-hidden="true"
                                          class="sr-only"
                                        >
                                          Clear
                                        </span>
                                      </i>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <b-col>
                              <b-form-group class="width-140px">
                                <b-input-group class="flatpickr">
                                  <flat-pickr
                                    v-model="data.item.to"
                                    class="form-control"
                                    :config="flatPickrConfig"
                                    placeholder="To"
                                  />
                                  <b-input-group-append>
                                    <b-button
                                      variant="outline-dark"
                                      title="Clear"
                                      class="brdr-gray px-1"
                                      data-clear
                                    >
                                      <i class="fa fa-times">
                                        <span
                                          aria-hidden="true"
                                          class="sr-only"
                                        >
                                          Clear
                                        </span>
                                      </i>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>

                        </template>

                      </b-table>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label=" Notes"
                        label-for="notes"
                      >
                        <label
                          class="sr-only"
                          for="notes"
                        >
                          Notes
                        </label>
                        <b-form-textarea
                          id="notes"
                          ref="Notes"
                          v-model="employmentSection.notes"
                          placeholder="Notes"
                          rows="3"
                          max-rows="6"
                          title="Notes"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <p class="text-center mt-2 mb-2">
                    <strong>I hereby certify under penalty of perjury that I am 18 years of age or older and that the above statements are true and correct. I give my permission for any necessary verification.</strong>
                  </p>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Employee Signature"
                        label-for="emp-signature"
                      >
                        <label
                          class="sr-only"
                          for="emp-signature"
                        >
                          * Employee Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="empSignature"
                            v-model="employmentSection.empSignature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && employmentSection.empSignature === ''}"
                            title="Employee Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="employmentSection.empSignature === ''"
                                class="text-danger"
                              >{{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="date-signed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-signed"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="date-signed"
                            ref="dateSigned"
                            v-model="employmentSection.dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>

                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr14/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm()"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form14a', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form14c', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroupPrepend, BInputGroupAppend, BInputGroup, BFormRadioGroup, BFormRadio, VBToggle, BFormTextarea, BTable,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { VueSignaturePad } from 'vue-signature-pad'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mask } from 'vue-the-mask'
import store from '@/store'
import router from '@/router'
import { mapState } from 'vuex'
import { ref, onUnmounted } from '@vue/composition-api'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BFormTextarea,
    BTable,
    VueSignaturePad,

    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr14'
    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })
    // const form = ref({})
    if (router.currentRoute.params.action === 'add') {
      // Do something
    }

    const form = ref({})

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      wrap: true,
      dateFormat: 'Y-m-d',
    }
    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,
      flatPickrConfig,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      // data
      id: this.$route.query.id,
      action: this.$route.params.action,
      isNotSaved: false,
      forms: this.$route.name,
      formUpdateChanges: [],
      required,
      homeCareOrgSection: {
        homeCareName: '',
        homeCareAddress: '',
        homeCareNumber: '',
        dateEmployment: '',
        dateSeparation: '',
      },
      personalSection: {
        lastName: '',
        firstName: '',
        middleName: '',
        telephone: '',
        address: '',
        dateBirth: '',
        ssn: '',
        dateLastTBTest: '',
        resultsLastTBTest: '',
        diffName: '',
        allDiffName: '',
        driverLicense: '',
        cdlNumber: '',
      },
      positionInfoSection: {
        position: '',
        timebase: '',
      },
      employmentSection: {
        tableItems: [
          {
            id: 1,
            empNameAddress: '',
            tel: '',
            jobTitle: '',
            reason: '',
            from: '',
            to: '',
            signature: '',
          },
          {
            id: 2,
            empNameAddress: '',
            tel: '',
            jobTitle: '',
            reason: '',
            from: '',
            to: '',
          },
          {
            id: 3,
            empNameAddress: '',
            tel: '',
            jobTitle: '',
            reason: '',
            from: '',
            to: '',
          },
          {
            id: 4,
            empNameAddress: '',
            tel: '',
            jobTitle: '',
            reason: '',
            from: '',
            to: '',
          },
          {
            id: 5,
            empNameAddress: '',
            tel: '',
            jobTitle: '',
            reason: '',
            from: '',
            to: '',
          },
        ],
        notes: '',
        empSignature: '',
        dateSigned: '',
      },
      formData: [],
      // table fields
      fields: [
        {
          key: 'empNameAddress',
          label: 'Name and Address of Employer',
          thClass: 'text-center align-middle',
        },
        {
          key: 'tel',
          label: 'Area Code / Telephone',
          thClass: 'text-center align-middle',
        },
        {
          key: 'jobTitle',
          label: 'Job Title and Type of Work',
          thClass: 'text-center align-middle',
        },
        {
          key: 'reason',
          label: 'Reason for Leaving',
          thClass: 'text-center align-middle',
        },
        {
          key: 'dates',
          label: 'Dates',
          thClass: 'text-center align-middle',
        },
      ],
      // options
      radioYN: [
        {
          id: 1,
          text: 'Yes',
        },
        {
          id: 2,
          text: 'No',
        },
      ],
      // static val
      diffNameDisabled: 1,
      driverLicenseDisabled: 1,
    }
  },
  computed: {
    ...mapState('app-hr', ['uploadPercentage']),
    // ...mapGetters('app-hr', { uploadPercentage: 'getUploadPercentage' }),
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        // if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
        let valCopy = this.formCopy[key]
        if (Array.isArray(this.formCopy[key])) {
          valCopy = JSON.stringify(this.formCopy[key])
        }
        // console.log(key, val, valCopy, val !== valCopy)
        if (val !== valCopy) {
          if (key === 'empnameaddress' || key === 'tel' || key === 'jobtitle' || key === 'reason' || key === 'fromDate' || key === 'toDate') {
            this.formUpdateChanges.push(this.$refs.tableItems.$attrs.title)
          }
          try {
            const keys = Object.keys(this.$refs).filter(k => k.toLowerCase() === key)
            if (key === 'signature') {
              keys.push('empSignature')
            }
            this.formUpdateChanges.push(this.$refs[keys[0]].$attrs.title)
          } catch (error) {
            // console.error(error)
          }
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }

    if (this.$route.params.action === 'edit' && this.$route.query.id != null) {
      this.fetchForm14()
      this.fetchCaregiverData()
    }
    if (this.$route.params.action === 'add' && this.$route.query.id !== null) {
      this.fetchCaregiverData()
      this.fetchForm14()
    }
    if (this.$route.params.action === 'add') {
      this.fetchForm14()
      this.fetchCaregiverData()
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr14/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr14/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          // HOMECARE
          this.homeCareOrgSection.homeCareName = jsonObjectLiterals.data.homecarename
          this.homeCareOrgSection.homeCareAddress = jsonObjectLiterals.data.homecareaddress
          this.homeCareOrgSection.homeCareNumber = jsonObjectLiterals.data.homecarenumber
          this.homeCareOrgSection.dateSeparation = jsonObjectLiterals.data.dateseparation
          // PERSONAL
          this.personalSection.dateLastTBTest = jsonObjectLiterals.data.datelasttbtest
          this.personalSection.resultsLastTBTest = jsonObjectLiterals.data.resultslasttbtest
          this.personalSection.diffName = jsonObjectLiterals.data.diffname
          this.personalSection.allDiffName = jsonObjectLiterals.data.alldiffname
          this.personalSection.driverLicense = jsonObjectLiterals.data.driverlicense
          this.personalSection.cdlNumber = jsonObjectLiterals.data.cdlnumber
          this.positionInfoSection.timebase = jsonObjectLiterals.data.timebase
          this.employmentSection.empSignature = jsonObjectLiterals.data.signature
          this.$refs.empSignature.clearSignature()
          this.$refs.empSignature.fromDataURL(jsonObjectLiterals.data.signature)

          // EMPLOYMENT
          const jobTitle = JSON.parse(jsonObjectLiterals.data.jobtitle)
          const empNameAddress = JSON.parse(jsonObjectLiterals.data.empnameaddress)
          const reason = JSON.parse(jsonObjectLiterals.data.reason)
          const fromDate = JSON.parse(jsonObjectLiterals.data.fromDate)
          const toDate = JSON.parse(jsonObjectLiterals.data.toDate)
          const tel = JSON.parse(jsonObjectLiterals.data.tel)

          for (let i = 0; i < 4; i += 1) {
            this.employmentSection.tableItems[i].jobTitle = jobTitle[i]
            this.employmentSection.tableItems[i].empNameAddress = empNameAddress[i]
            this.employmentSection.tableItems[i].reason = reason[i]
            this.employmentSection.tableItems[i].from = fromDate[i]
            this.employmentSection.tableItems[i].to = toDate[i]
            this.employmentSection.tableItems[i].tel = tel[i]
          }
          this.employmentSection.notes = jsonObjectLiterals.data.notes
          this.employmentSection.dateSigned = jsonObjectLiterals.data.datesigned

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr14/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr14/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    saveForm() {
      const self = this
      const formData = {
        homeCareOrgSection: this.homeCareOrgSection,
        personalSection: this.personalSection,
        positionInfoSection: this.positionInfoSection,
        employmentSection: this.employmentSection,
        cid: this.id,
        id: this.form.id,
      }

      // HOMECARE
      this.form.homecarename = this.homeCareOrgSection.homeCareName
      this.form.homecareaddress = this.homeCareOrgSection.homeCareAddress
      this.form.homecarenumber = this.homeCareOrgSection.homeCareNumber
      this.form.dateseparation = this.homeCareOrgSection.dateSeparation
      // PERSONAL
      this.form.datelasttbtest = this.personalSection.dateLastTBTest
      this.form.resultslasttbtest = this.personalSection.resultsLastTBTest
      this.form.diffname = this.personalSection.diffName
      this.form.alldiffname = this.personalSection.allDiffName
      this.form.driverlicense = this.personalSection.driverLicense
      this.form.cdlnumber = this.personalSection.cdlNumber
      this.form.timebase = this.positionInfoSection.timebase
      this.form.signature = this.employmentSection.empSignature

      // console.log(this.employmentSection.tableItems)

      // EMPLOYMENT
      const jobTitle = []
      const empNameAddress = []
      const reason = []
      const fromDate = []
      const toDate = []
      const tel = []

      for (let i = 0; i < 4; i += 1) {
        jobTitle[i] = this.employmentSection.tableItems[i].jobTitle
        empNameAddress[i] = this.employmentSection.tableItems[i].empNameAddress
        reason[i] = this.employmentSection.tableItems[i].reason
        fromDate[i] = (this.employmentSection.tableItems[i].from !== '' ? this.employmentSection.tableItems[i].from : null)
        toDate[i] = (this.employmentSection.tableItems[i].to !== '' ? this.employmentSection.tableItems[i].to : null)
        tel[i] = this.employmentSection.tableItems[i].tel
      }

      this.form.jobtitle = JSON.stringify(jobTitle)
      this.form.empnameaddress = JSON.stringify(empNameAddress)
      this.form.reason = JSON.stringify(reason)
      this.form.fromDate = JSON.stringify(fromDate)
      this.form.toDate = JSON.stringify(toDate)
      this.form.tel = JSON.stringify(tel)

      this.form.notes = this.employmentSection.notes
      this.form.datesigned = this.employmentSection.dateSigned

      // console.log(JSON.stringify(this.form), JSON.stringify(this.formCopy), JSON.stringify(this.form) !== JSON.stringify(this.formCopy))

      let msg = 'Do you want to save the changes?'
      if (this.action === 'add') msg = 'Do you want to add these data?'
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: msg,
        confirmButtonText: 'Proceed',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark mr-1',
        },
        buttonsStyling: false,
        returnFocus: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.isNotSaved = true
          store
            .dispatch(this.action === 'add' ? 'app-hr14/addForm14b' : 'app-hr14/updateForm14b', formData)
            .then(response => {
              // eslint-disable-next-line
              const jsonObjectLiterals = response.data.response.data

              this.form.id = jsonObjectLiterals.id

              if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                self.requestFormUpdate()
              }

              Swal.fire({
                icon: 'success',
                // eslint-disable-next-line
                title: 'Saved!',
                text: 'Saved succesfully.',
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1500,
              })
              console.error(error)
            })
            .finally(() => {
              self.isNotSaved = false
            })
        }
      })
    },
    fetchForm14() {
      store
        .dispatch('app-hr14/fetchForm14b', { id: this.id })
        .then(response => {
          const data = response.data.response.data[0]
          const obj = data

          // HOMECARE
          this.homeCareOrgSection.homeCareName = obj.homecarename
          this.homeCareOrgSection.homeCareAddress = obj.homecareaddress
          this.homeCareOrgSection.homeCareNumber = obj.homecarenumber
          this.homeCareOrgSection.dateSeparation = obj.dateseparation
          // PERSONAL
          this.personalSection.dateLastTBTest = obj.datelasttbtest
          this.personalSection.resultsLastTBTest = obj.resultslasttbtest
          this.personalSection.diffName = obj.diffname
          this.personalSection.allDiffName = obj.alldiffname
          this.personalSection.driverLicense = obj.driverlicense
          this.personalSection.cdlNumber = obj.cdlnumber
          this.positionInfoSection.timebase = obj.timebase
          this.employmentSection.empSignature = obj.signature
          this.$refs.empSignature.clearSignature()
          this.$refs.empSignature.fromDataURL(obj.signature)

          // EMPLOYMENT
          const jobTitle = JSON.parse(obj.jobtitle)
          const empNameAddress = JSON.parse(obj.empnameaddress)
          const reason = JSON.parse(obj.reason)
          const fromDate = JSON.parse(obj.fromDate)
          const toDate = JSON.parse(obj.toDate)
          const tel = JSON.parse(obj.tel)

          for (let i = 0; i < 4; i += 1) {
            this.employmentSection.tableItems[i].jobTitle = jobTitle[i]
            this.employmentSection.tableItems[i].empNameAddress = empNameAddress[i]
            this.employmentSection.tableItems[i].reason = reason[i]
            this.employmentSection.tableItems[i].from = fromDate[i]
            this.employmentSection.tableItems[i].to = toDate[i]
            this.employmentSection.tableItems[i].tel = tel[i]
          }
          this.employmentSection.notes = obj.notes
          this.employmentSection.dateSigned = obj.datesigned

          this.form = obj

          this.form.id = JSON.stringify(data.id)

          this.form.cid = JSON.stringify(data.cid)

          if (this.form.cid) {
            this.form.form = 'folder4_form1ab'
            this.form.formType = 'b'

            this.formCopy = JSON.parse(JSON.stringify(this.form))

            store.dispatch('app-hr14/fetchFormUpdate', { form: this.form.form, formType: this.form.formType, form_id: this.form.cid })
              .then(response2 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response2.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.status) {
                  this.formUpdate = jsonObjectLiterals.data

                  this.formUpdateStatus = this.formUpdate.status
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
      if (this.radioYN[0].text === 'No') {
        this.diffNameDisabled = 0
      }
      if (this.radioYN[1].text === 'No') {
        this.driverLicenseDisabled = 0
      }
    },
    fetchCaregiverData() {
      store
        .dispatch('app-hr14/fetchCaregiverData', { id: this.id })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data[0]

          this.personalSection.firstName = obj.firstname
          this.personalSection.lastName = obj.lastname
          this.personalSection.middleName = obj.middlename
          this.personalSection.telephone = obj.phonenumber
          this.homeCareOrgSection.dateEmployment = obj.employmentstatusdate
          // eslint-disable-next-line
          this.personalSection.address = obj.citytown + ' ' + obj.streetpob + ' ' + obj.state
          this.personalSection.ssn = obj.ssn
          this.personalSection.dateBirth = obj.dateofbirth
          this.positionInfoSection.position = obj.position
        })
        .catch(error => {
          console.error(error)
        })
    },
    clearSignature() {
      this.$refs.empSignature.clearSignature()
    },
    undoSignature() {
      this.$refs.empSignature.undoSignature()
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.empSignature.saveSignature()
      this.employmentSection.empSignature = !isEmpty ? data : ''
    },
    validationForm() {
      /* const { isEmpty, data } = this.$refs.empSignature.saveSignature()
      this.employmentSection.empSignature = !isEmpty ? data : '' */
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.saveForm()
          // console.log(success)
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    dnDisabled(id) {
      if (id === 2) {
        this.diffNameDisabled = 1
        this.personalSection.allDiffName = ''
      } else {
        this.diffNameDisabled = 0
      }
    },
    dlDisabled(id) {
      if (id === 2) {
        this.driverLicenseDisabled = 1
        this.personalSection.cdlNumber = ''
      } else {
        this.driverLicenseDisabled = 0
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.min-width-300px
{
  min-width: 300px;
}

.min-width-140px
{
  min-width: 140px;
}

.mt-1rem {
  margin-top: 1rem;
}

</style>

<style scoped>
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
